window.addEventListener('DOMContentLoaded', event => {

  // Toggle the side navigation
  const sidebarToggle = document.body.querySelector('#sidebarCollapse');

  if (sidebarToggle) {
      sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          $('#sidebar').toggleClass('active');
      });
  }

  const collapseElementList = [].slice.call(document.querySelectorAll('.card-collapsible'));

  if (collapseElementList) {
    collapseElementList.map(function (collapseElement) {
      collapseElement.addEventListener('click', event => {
        event.preventDefault();
        // Collapse the element
        new bootstrap.Collapse(collapseElement.closest('.card').querySelector('.collapse'));
        // Toggle collapsed class in `.card-header` element
        collapseElement.closest('.card-header').classList.toggle('collapsed');
        // Toggle class ti-chevron-down & ti-chevron-right
        Helpers._toggleClass(collapseElement.firstElementChild, 'ti-chevron-down', 'ti-chevron-right');
      });
    });
  }
});

document.addEventListener('DOMContentLoaded', setupPreview);
function setupPreview(inputId, previewContainerId) {
  const fileInput = document.getElementById(inputId);
  const previewContainer = document.getElementById(previewContainerId);

  if (fileInput) {
    fileInput.addEventListener('change', function () {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = function (event) {
          const imgElement = document.createElement('img');
          imgElement.classList.add('preview-image');
          imgElement.src = event.target.result;
          previewContainer.innerHTML = ''; // Clear existing content
          previewContainer.appendChild(imgElement);
        };
        reader.readAsDataURL(file);
      } else {
        previewContainer.innerHTML = ''; // Clear preview if no file selected
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  var showPasswordButtons = document.querySelectorAll('.show-password-btn');
  var hidePasswordButtons = document.querySelectorAll('.hide-password-btn');

  showPasswordButtons.forEach(function(button) {
    button.addEventListener('click', function() {
      var passwordInput = this.previousElementSibling;
      passwordInput.type = 'text';
      this.style.display = 'none';
      this.nextElementSibling.style.display = 'inline-block';
    });
  });

  hidePasswordButtons.forEach(function(button) {
    button.addEventListener('click', function() {
      var passwordInput = this.previousElementSibling.previousElementSibling;
      passwordInput.type = 'password';
      this.style.display = 'none';
      this.previousElementSibling.style.display = 'inline-block';
    });
  });
});

document.addEventListener('DOMContentLoaded', function() {
  document.addEventListener('click', function(event) {
    if (event.target.classList.contains('copy-password-btn') || event.target.closest('.copy-password-btn')) {
      var passwordInput = event.target.closest('.input-group').querySelector('.password-input');
      copyToClipboard(passwordInput.value);
    }
  });

  function copyToClipboard(text) {
    var tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.focus();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    alert('Successfully copied to clipboard!');
  }
});

window.generateRandomNumber = function() {
  var array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] % 10000000000;
}

window.humanizeString = function(str) {
  var i, frags = str.split('_');
  for (i=0; i<frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

document.addEventListener('DOMContentLoaded', function() {
    // Initialize menu togglers and bind click on each
  let menuToggler = document.querySelectorAll('.layout-menu-toggle');
  menuToggler.forEach(item => {
    item.addEventListener('click', event => {
      event.preventDefault();
      window.Helpers.toggleCollapsed();
    });
  });
});

document.addEventListener('DOMContentLoaded', function() {
  $('#flash').delay(500).fadeIn('normal', function() {
      $(this).delay(10000).fadeOut();
  });
});
