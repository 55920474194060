function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      $('#thumbPreview').css('background-image', 'url('+e.target.result +')');
      $('#thumbPreview').hide();
      $('#thumbPreview').fadeIn(650);
      $('.thumb-upload').show();
    }
    reader.readAsDataURL(input.files[0]);
  }
}

function readURLLOGO(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      $('#logoPreview').css('background-image', 'url('+e.target.result +')');

      $('#logoPreview').hide();
      $('#logoPreviewsystem_default_logo').hide();
      $('#logoPreviewproject_logo').hide();
      $('#logoPreviewprofile_logo').hide();
      $('#logoPreviewhide_logo').hide();

      $('#logoPreview').fadeIn(650);
      $('.logo-upload').show();
    }
    reader.readAsDataURL(input.files[0]);
  }
}

function readURLBANNER(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      $('#bannerPreview').css('background-image', 'url('+e.target.result +')');

      $('#bannerPreview').hide();
      $('#bannerPreviewsystem_default_banner').hide();
      $('#bannerPreviewproject_banner').hide();
      $('#bannerPreviewprofile_banner').hide();
      $('#bannerPreviewhide_banner').hide();

      $('#bannerPreview').fadeIn(650);
      $('.banner-upload').show();
    }
    reader.readAsDataURL(input.files[0]);
  }
}

function readURLBackgroundImage(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      $('#backgroundPreview').css('background-image', 'url('+e.target.result +')');
      $('#backgroundPreview').hide();
      $('#backgroundPreview').fadeIn(650);
      $('.background-upload').show();
    }
    reader.readAsDataURL(input.files[0]);
  }
}

$(function() {
  $("#thumbUpload").change(function() {
    readURL(this);
  });

  $("#bannerUpload").change(function() {
    readURLBANNER(this);
  });

  $("#logoUpload").change(function() {
    readURLLOGO(this);
  });

  $("#backgroundImageUpload").change(function() {
    readURLBackgroundImage(this);
  });
});
