// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'jquery'
// import 'bootstrap'
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Chartkick from "@ockhamlabsinc/chartkick"
import Highcharts from "highcharts"
import * as ace from 'brace';
import 'brace/mode/sql';
import 'brace/theme/monokai';

import "@fortawesome/fontawesome-free/js/all"

window.templateName = 'vertical-menu-template-no-customizer';
window.rtlSupport = false;

Chartkick.use(Highcharts)
import { TabulatorFull as Tabulator } from "tabulator-tables";
window.Tabulator = Tabulator

import HighchartsMore from 'highcharts/highcharts-more'
HighchartsMore(Highcharts)

require('highcharts/modules/wordcloud')(Highcharts);
require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")
require('packs/helpers.js')
require('packs/hammer.js')
require('packs/menu.js')
require('packs/sortable.js')
require('packs/main.js')
require('packs/bs-stepper.js')
require('packs/bootstrap.js')
require('packs/blazer.js')
require('packs/image_show.js')
require('packs/custom.js')

Chartkick.options = {
  colors: ["#1D3557", "#AE2012", "#EE9B00", "#CA6702", "#005F73"]
}

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
